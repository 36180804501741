import React from 'react'
import { Helmet } from 'react-helmet'
import { BaseLayout, En } from '../components'

export default function Page() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>プロフィール</title>
        <meta name="og:title" content="プロフィール" />
      </Helmet>
      <BaseLayout>
        <h1>プロフィール</h1>

        <p>
          やまもりなつめと申します。当サイト<En>Struggle with English</En>を運営しながら英語力の向上に日々励んでいます。
        </p>

        <h2>英語学習をはじめたきっかけ</h2>

        <p>英語と縁もゆかりもなく社会人になって数年が経ったある頃、当時所属していた会社が英語ネイティブで日本語をほとんどしゃべれない人を採用しました。それをきっかけにチーム全体で、コミュニケーションは英語メインでやろうということになりました。</p>

        <p>当時の私は必要があれば英語の記事をインターネットで探してなんとか時間をかけて理解する、ぐらいにしか英語と触れていなかったのでかなり焦りました。そこから急いでオンライン英会話を初めたのですが、しばらくして、その英語ネイティブな方は別のチームに異動になり、結局英語を本格的に使うこともなく今に至っています。</p>

        <h2>英語学習は長い闘い、でも楽しい</h2>

        <p>英語をはじめた最初のゴールはなくなってしまいましたが、しばらくして英語学習自体が楽しいと思うようになりました。そして、最初に英語学習をはじめて数年経ちますが、今も細く長く英語の勉強を続けています。当時に比べると英語で「書く」こと「読む」ことはそれなりにスキルアップしたと思うのですが、「聞く」ことと「話す」ことは相変わらず苦戦しています。</p>

        <p>英語学習は終わりのない闘いだと思っています。非英語ネイティブからすれば英単語なんて無限に存在するように感じますし、いくら時間をかけても効果が実感しにくいです。しかし、英語学習それ自体が楽しいです。本当に少しずつですが、わかる単語が増えたり、今まで聞きとれなかったフレーズがわかるようになったり、今まで使えなかった表現を使えたりなど、続ければ些細な変化が感じられます。それが個人的に英語学習の魅力的な点です。</p>

        <p>そうした長い闘いをサポートするため、このサイトでは英語学習のノウハウや「お、これは面白いな」と思った英語関連のトピックについて取り上げて公開していくことで、同じように英語を勉強している方の役に立ち、ひいては自分自身のスキルアップにもつながるように活動していきたいと思っています。</p>

        <p>どうぞよろしくお願いいたします。</p>
      </BaseLayout>
    </>
  )
}
